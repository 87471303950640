.search__input.field__input {
  padding-right: 5rem;
}

.search__button {
  right: var(--inputs-border-width);
  top: var(--inputs-border-width);
}

.search__button:focus-visible {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.search__button:focus {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.search__button:not(:focus-visible):not(.focused){
  box-shadow: inherit;
  background-color: inherit;
}

.search__button .icon {
  height: 1.8rem;
  width: 1.8rem;
}

/* Remove extra spacing for search inputs in Safari */
input::-webkit-search-decoration {
  -webkit-appearance: none;
}
